import styled from '@emotion/styled';
import { useNavigate } from 'react-router';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import { useProgressBar } from 'components/atoms/ProgressBar';
import ProgressSpinner from 'components/atoms/ProgressSpiner';
import MainLayout from 'components/templates/MainLayout';
import { ReactComponent as CloseIcon } from 'images/cmdClose16.svg';
import { BreadCrumb } from 'pages/Project/BreadCrumb';
import { Path } from 'types/paths.enum';

const XccHeader = styled(Box)({
    backgroundColor: 'white',
    height: '32px',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '8px',
});

const XccViewerContainer = styled(Box)({
    height: '100%',
});

const XccSimpleViewer = ({
    onClose,
    loading,
    url,
    projectName,
}: {
    onClose: () => void;
    loading: boolean;
    url: string;
    projectName: string | undefined;
}) => {
    const { setProgressBar } = useProgressBar();
    const navigate = useNavigate();

    return (
        <MainLayout stretchViewer>
            {loading ? (
                <ProgressSpinner label={'Preparing viewer'} />
            ) : (
                <Box
                    css={{
                        height: '100%',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <XccHeader>
                        <BreadCrumb
                            projectName={projectName ?? ''}
                            onProject={() => {
                                navigate(Path.PROJECTS);
                            }}
                        />
                        <Button variant={'text'} onClick={onClose} css={{ cursor: 'pointer' }}>
                            <CloseIcon css={{ width: '16px', height: '16px' }} />
                        </Button>
                    </XccHeader>
                    <XccViewerContainer>
                        {url && (
                            <iframe
                                src={url}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    border: 'transparent',
                                }}
                                onError={() => {
                                    setProgressBar(false);
                                }}
                                onLoad={() => {
                                    setProgressBar(false);
                                }}
                            />
                        )}
                    </XccViewerContainer>
                </Box>
            )}
        </MainLayout>
    );
};

export default XccSimpleViewer;
