import { Route, Routes, useLocation } from 'react-router-dom';

import Projects from 'pages/AllProjects';
import Home from 'pages/Home';
import JTViewer from 'pages/JTViewer';
import Project from 'pages/Project';
import RecentProjects from 'pages/RecentProjects';
import StaticMissingAsset from 'pages/StaticAssetMissing/StaticMissingAsset';
import StaticError from 'pages/StaticError';
import StaticError403 from 'pages/StaticError403';
import StaticError404 from 'pages/StaticError404';
import StaticErrorSubscription from 'pages/StaticErrorSubscription';
import StaticLoggedOut from 'pages/StaticLoggedOut';
import StaticProvisioning from 'pages/StaticProvisioning';
import StaticTermsOfUse from 'pages/StaticTermsOfUse';
import SubscriptionManager from 'pages/SubscriptionManager';
import Unsubscribed from 'pages/Unsubscribed';
import XccCompareViewer from 'pages/XccViewer/XccCompareViewer';
import XccViewer from 'pages/XccViewer/XccViewer';
import useTranslateRoute from 'providers/router/useTranslateRoute';
import { Title } from 'types/pageTitle.enum';
import { Path } from 'types/paths.enum';

import LoginRoute from './LoginRoute';
import LogoutRoute from './LogoutRoute';
import PrivateRoute from './PrivateRoute';

const Router = () => {
    const { isTranslatingUrl } = useTranslateRoute();

    const location = useLocation();
    const allowRedirection = [Path.HOME, Path.RECENT, Path.PROJECTS, Path.XCC, Path.ACC];
    if (allowRedirection.find((item) => item === `/${location.pathname.split('/')[1]}`)) {
        window.localStorage.setItem('lastLocation', JSON.stringify(location.pathname));
    }

    if (isTranslatingUrl) {
        return null;
    }

    return (
        <Routes>
            <Route path={Path.LOGOUT} element={<LogoutRoute />} />
            <Route path={Path.LOGGED_OUT} element={<StaticLoggedOut />} />
            <Route path={Path.LOGGED_OUT_HTML} element={<StaticLoggedOut />} />
            <Route path={Path.THIRD_PARTY} element={<StaticTermsOfUse />} />
            <Route path={Path.ERROR_UNSUBSCRIBED_USER} element={<Unsubscribed />} />
            <Route path={Path.ERROR_UNSUBSCRIBED_USER_HTML} element={<Unsubscribed />} />
            <Route path={Path.HOME} element={<PrivateRoute to={<Home />} />} />
            <Route path={Path.LOGIN} element={<PrivateRoute to={<LoginRoute />} title={Title.LOGIN} />} />
            <Route path={Path.PROVISIONING} element={<PrivateRoute to={<StaticProvisioning />} />} />
            <Route path={Path.PROVISIONING_HTML} element={<PrivateRoute to={<StaticProvisioning />} />} />
            <Route path={Path.ERROR} element={<PrivateRoute to={<StaticError />} />} />
            <Route path={Path.LOGIN_ERROR_HTML} element={<PrivateRoute to={<StaticError />} />} />
            <Route path={Path.ERROR_HTML} element={<PrivateRoute to={<StaticError />} />} />
            <Route path={Path.ERROR_403} element={<PrivateRoute to={<StaticError403 />} />} />
            <Route path={Path.ERROR_404} element={<PrivateRoute to={<StaticError404 />} />} />
            <Route path={Path.ERROR_SUB} element={<PrivateRoute to={<StaticErrorSubscription />} />} />
            <Route path={Path.RECENT} element={<PrivateRoute to={<RecentProjects />} title={Title.HOME} />} />
            <Route path={Path.PROJECTS}>
                <Route path={Path.PARAMS_PROJECT_ID} element={<PrivateRoute to={<Project />} title={Title.PROJECT_DETAILS} />} />
                <Route path={Path.PARAMS_PROJECT_ID_REVISION_ID} element={<PrivateRoute to={<Project />} title={Title.PROJECT_DETAILS} />} />
                <Route path={Path.PARAMS_PROJECT_ID_REVISION_ID_TAB} element={<PrivateRoute to={<Project />} title={Title.PROJECT_DETAILS} />} />
                <Route path={Path.EMPTY_ROUTE} element={<PrivateRoute to={<Projects />} title={Title.PROJECT_LIST} />} />
                <Route path={Path.PARAMS_PROJECT_ID_REVISION_ID_MISSING_ASSET} element={<PrivateRoute to={<StaticMissingAsset />} title={Title.PROJECT_DETAILS} />} />
            </Route>
            <Route path={Path.PARAMS_XCC_PROJECT_ID_REV_ID_DESIGN_TYPE_MODE} element={<PrivateRoute to={<XccViewer />} title={Title.PROJECT_VIEWER} />} />
            <Route path={Path.PARAMS_XCC_COMPARE_PROJECT_ID_REV_ID_DESIGN_TYPE_MODE} element={<PrivateRoute to={<XccCompareViewer />} title={Title.PROJECT_VIEWER} />} />
            <Route path={Path.PARAMS_ACC_PROJECT_ID_REV_ID_REV_URM} element={<PrivateRoute to={<JTViewer />} title={Title.PROJECT_VIEWER} />} />
            <Route path={Path.SM} element={<PrivateRoute to={<SubscriptionManager />} title={Title.SUBSCRIPTION_MANAGER} />}></Route>
            <Route path={Path.EMPTY_ROUTE} element={<PrivateRoute to={<Projects />} title={Title.PROJECT_LIST} />} />
            <Route path={Path.ANY_ROUTE} element={<PrivateRoute to={<StaticError404 />} />} />
        </Routes>
    );
};

export default Router;
