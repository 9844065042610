import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useProvisioningStatus } from 'services/queries/auth.query';
import { ProvisioningStatus } from 'types/auth';
import { Path } from 'types/paths.enum';

const useProvisioning = () => {
    const [previousProvisioningStatus, setPreviousProvisioningStatus] = useState<
        ProvisioningStatus | undefined
    >(undefined);
    const navigate = useNavigate();

    const redirectToProvisioning = () => {
        if (
            ![Path.PROVISIONING, Path.PROVISIONING_HTML].includes(window.location.pathname as Path)
        ) {
            navigate(Path.PROVISIONING);
        }
    };

    const redirectToHome = () => {
        if (
            [Path.PROVISIONING, Path.PROVISIONING_HTML].includes(window.location.pathname as Path)
        ) {
            navigate(Path.HOME);
        }
    };

    const { data: provisioningStatus } = useProvisioningStatus(
        previousProvisioningStatus !== 'Finished'
    );

    useEffect(() => {
        try {
            if (provisioningStatus?.status === 'Finished') {
                redirectToHome();
            } else if (
                provisioningStatus?.status === 'NotStarted' ||
                provisioningStatus?.status === 'InProgress'
            ) {
                redirectToProvisioning();
            } else if (
                previousProvisioningStatus === 'InProgress' &&
                provisioningStatus?.status === 'NotStarted'
            ) {
                navigate(Path.LOGIN_ERROR_HTML);
            }

            setPreviousProvisioningStatus(provisioningStatus?.status);
        } catch (error) {
            console.warn('Unable to fetch provisioning');
        }
    }, [provisioningStatus]);

    return {
        progress: (provisioningStatus?.progress || 0) * 100,
        isFinished: provisioningStatus?.status === 'Finished',
    };
};

export default useProvisioning;
