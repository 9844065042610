import { useNavigate, useParams } from 'react-router';

import Box from 'components/atoms/Box';
import { Center } from 'components/atoms/Flex/Flex';
import ErrorPage, { ErrorButton } from 'components/templates/ErrorPage';
import { ReactComponent as MissingAsset } from 'images/miscMissinginSourceHover16.svg';
import { customColors } from 'theme';
import { Path } from 'types/paths.enum';

const ErrorLabel = () => (
    <Center css={{ flexDirection: 'column' }}>
        <MissingAsset />
        <Box
            as={'span'}
            css={{
                fontWeight: '700',
                fontSize: '30px',
                lineHeight: '40px',
                height: '40px',
                color: customColors['blue-7'],
                marginTop: '24px',
            }}
        >
            Oops! ASSET MISSING
        </Box>
    </Center>
);

const StaticMissingAsset = () => {
    const { projectId } = useParams();
    const navigate = useNavigate();
    return (
        <ErrorPage errorLabel={<ErrorLabel />} showReturnHome={false}>
            <Box as={'h1'} css={{ margin: 0 }}>
                We can’t load the asset you are trying to reach.
            </Box>
            <Box as={'p'} css={{ marginTop: 0 }}>
                If someone shared this asset with you, make sure with the owner the asset hasn’t
                been removed.
            </Box>
            <p>You can ask the owner to share the asset with you one more time. </p>
            <p> Alternatively, we can direct you to the latest version we can detect.</p>
            <ErrorButton
                onClick={() => navigate(Path.RECENT)}
                variant={projectId ? 'outlined' : 'contained'}
            >
                Return Home
            </ErrorButton>
            {projectId && (
                <ErrorButton
                    onClick={() => navigate(`${Path.PROJECTS}/${projectId}`)}
                    variant={'contained'}
                    css={{ width: 'auto' }}
                >
                    Use latest version
                </ErrorButton>
            )}
        </ErrorPage>
    );
};

export default StaticMissingAsset;
