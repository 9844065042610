import { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { XccCompareProvider } from 'hooks/XccCompareContextProvider';
import { ApiProvider, ThemeProvider, XccInfrastructureProvider } from 'providers';
import { AuthProvider } from 'providers/authorization/AuthProvider';
import { FullScreenProvider } from 'providers/fullScreen/FullScreenProvider';
import TopMarginProvider from 'providers/topMargin/TopMarginProvider';

export function ConnectProviders({ children }: PropsWithChildren): JSX.Element {
    return (
        <ApiProvider>
            <ThemeProvider>
                <BrowserRouter>
                    <TopMarginProvider>
                        <AuthProvider>
                            <FullScreenProvider>
                                <XccCompareProvider>
                                    <XccInfrastructureProvider>
                                        {children}
                                    </XccInfrastructureProvider>
                                </XccCompareProvider>
                            </FullScreenProvider>
                        </AuthProvider>
                    </TopMarginProvider>
                </BrowserRouter>
            </ThemeProvider>
        </ApiProvider>
    );
}

export default ConnectProviders;
