import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import Tooltip from 'components/atoms/Tooltip';
import editIcon from 'images/cmdEdit16.svg';

import {
    BreadCrumbBox,
    BreadCrumbItem,
    BreadCrumbItems,
    BreadCrumbTrigger,
    Icon,
} from './BreadCrumb.styles';

interface BreadCrumbInterface {
    projectName: string;
    onProject: () => void;
    onEdit?: () => void;
}

export const BreadCrumb = ({ projectName, onProject, onEdit }: BreadCrumbInterface) => {
    return (
        <BreadCrumbBox>
            <NavigationMenu.Root>
                <BreadCrumbItems>
                    <NavigationMenu.Item>
                        <BreadCrumbTrigger onClick={onProject}>Projects</BreadCrumbTrigger>
                    </NavigationMenu.Item>
                    <NavigationMenu.Item>
                        <BreadCrumbItem>{projectName}</BreadCrumbItem>
                    </NavigationMenu.Item>
                    {onEdit ? (
                        <NavigationMenu.Item>
                            <Tooltip title={'Edit project'} arrow enterDelay={500} placement='top'>
                                <Icon src={editIcon} onClick={onEdit} />
                            </Tooltip>
                        </NavigationMenu.Item>
                    ) : null}
                </BreadCrumbItems>
            </NavigationMenu.Root>
        </BreadCrumbBox>
    );
};
