import ErrorPage from 'components/templates/ErrorPage';
import { ReactComponent as ErrorLabel } from 'images/error_generic.svg';

const StaticError = () => {
    return (
        <ErrorPage showLogout errorLabel={<ErrorLabel />}>
            <h1>This page didn't load Connect correctly.</h1>
            <p>
                Please try to log out first or use the Return Home button to rule out a web glitch.
                <br />
                If you have seen this message multiple times, please contact our support.
            </p>
        </ErrorPage>
    );
};

export default StaticError;
