import styled from '@emotion/styled';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';

import Box from 'components/atoms/Box';
import { useProgressBar } from 'components/atoms/ProgressBar';
import MainLayout from 'components/templates/MainLayout';
import { ReactComponent as CloseIcon } from 'images/cmdClose16.svg';
import { SubscriptionManagerContext } from 'providers/subscriptionManager/SubscriptionManagerProvider';
import { Path } from 'types/paths.enum';

const SubscriptionManager = () => {
    const { setProgressBar } = useProgressBar();
    const subscriptionManagerContext = useContext(SubscriptionManagerContext);
    const navigator = useNavigate();

    const SubscriptionManagerContainer = styled('div')({
        width: '100%',
        height: 'calc(100% - 24px)',
        overflowY: 'hidden',
        overflowX: 'hidden',
    });
    const SubscriptionManagerHeader = styled('div')({
        backgroundColor: 'white',
        width: 'calc(100% + 45px)',
        marginLeft: '-30px',
        height: '32px',
    });

    const CloseButton = styled(CloseIcon)(({ theme }) => ({
        float: 'right',
        position: 'relative',
        top: '8px',
        right: '6px',
        width: '16px',
        height: '16px',
        marginRight: theme.spacing(5),
        cursor: 'pointer',
    }));

    const closeViewer = () => {
        navigator('/');
    };

    useEffect(() => {
        setProgressBar(true);
        return () => setProgressBar(false);
    }, []);

    useEffect(() => {
        if (!subscriptionManagerContext.smEnabled && !subscriptionManagerContext.smChecking) {
            setProgressBar(false);
            navigator(Path.ERROR_403);
        }
    }, [subscriptionManagerContext.smEnabled, subscriptionManagerContext.smChecking]);

    return (
        <MainLayout>
            <Box
                css={{
                    overflow: 'hidden',
                    width: '100%',
                    height: '100%',
                }}
            >
                <SubscriptionManagerHeader>
                    <CloseButton onClick={closeViewer} />
                </SubscriptionManagerHeader>
                <SubscriptionManagerContainer>
                    {subscriptionManagerContext.smEnabled && subscriptionManagerContext.smUrl ? (
                        <iframe
                            src={subscriptionManagerContext.smUrl}
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'transparent',
                            }}
                            onLoad={() => setProgressBar(false)}
                        ></iframe>
                    ) : null}
                </SubscriptionManagerContainer>
            </Box>
        </MainLayout>
    );
};

export default SubscriptionManager;
