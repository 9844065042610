import React, { useEffect, useState } from 'react';

import useChangeView from 'components/_legacy/hooks/useChangeView';
import {
    MenuElementType,
    TButtons,
    VerticalMenu,
} from 'components/molecules/VerticalMenu/VerticalMenu';
import { VerticalMenuViewType } from 'components/molecules/VerticalMenu/VerticalMenu';
import { SideNavMode } from 'components/organisms/AddUpdateProject/enums/sideNavMode';
import {
    addNewProjectBeginEvent,
    addNewProjectFinishEvent,
} from 'components/templates/ProjectEditView/events';
import useSortTiles, { OnGridSort, SortType } from 'hooks/useSortTiles';
import { ReactComponent as AddNewIcon } from 'images/cmdAdd16.svg';
import { ReactComponent as DeleteIcon } from 'images/cmdDelete16.svg';
import { ReactComponent as EditIcon } from 'images/cmdEdit16.svg';
import { ReactComponent as LockIcon } from 'images/lock16.svg';
import { ReactComponent as UnLockIcon } from 'images/unlock16.svg';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { useCollaborationSpacePermission, useProjectPermission } from 'providers/permissions';
import { CollaborationSpacePermissions, ProjectPermissions } from 'types/permissions';
import { Project } from 'types/project';

import { useLockProject } from '../hooks/useLockProject';
import { useProjectDelete } from '../hooks/useProjectDelete';
import { useUnlockProject } from '../hooks/useUnlockProject';

export type OnViewChange = (viewType: VerticalMenuViewType) => void;
export interface AllProjectsMenuInterface {
    viewType: string;
    viewTypeChange: OnViewChange;
    sideNavStateChange: (mode: SideNavMode) => void;
    currentSelection: Project;
    hideModifyButtons: boolean;
    sort: {
        onSort: OnGridSort;
        visible: boolean;
        sortType: SortType;
    };
}

export function AllProjectsMenu({
    viewType,
    viewTypeChange,
    sideNavStateChange,
    currentSelection,
    hideModifyButtons,
    sort,
}: AllProjectsMenuInterface) {
    const [actionLocked, setActionLocked] = useState(false);
    const { onSort, visible, sortType } = sort;
    const { sortTiles } = useSortTiles(onSort, visible, sortType);
    const { changeView } = useChangeView(viewTypeChange, viewType, actionLocked);
    const {
        deleteProjectDialog,
        openDeleteProjectDialog,
        inProgress: deleteInProgress,
    } = useProjectDelete(currentSelection);
    const {
        lockProjectDialog,
        openLockProjectDialog,
        inProgress: lockInProgress,
    } = useLockProject(currentSelection);
    const {
        unlockProjectDialog,
        openUnlockProjectDialog,
        forceProjectDialog,
        lockedProjectDialog,
        inProgress: unlockInProgress,
    } = useUnlockProject(currentSelection);
    const { collaborationSpaceId } = useUserDataContext();
    const { hasCollaborationSpacePermission } =
        useCollaborationSpacePermission(collaborationSpaceId);
    const { hasProjectPermission } = useProjectPermission(
        currentSelection.collaborationSpaceId,
        currentSelection.projectId
    );

    const onAddNewProjectBeginEvent = () => setActionLocked(true);
    const onAddNewProjectFinishEvent = () => setActionLocked(false);

    useEffect(() => {
        document.addEventListener(addNewProjectBeginEvent, onAddNewProjectBeginEvent);
        document.addEventListener(addNewProjectFinishEvent, onAddNewProjectFinishEvent);
        return () => {
            document.removeEventListener(addNewProjectBeginEvent, onAddNewProjectBeginEvent);
            document.removeEventListener(addNewProjectFinishEvent, onAddNewProjectFinishEvent);
        };
    }, []);

    useEffect(
        () => setActionLocked(deleteInProgress || lockInProgress || unlockInProgress),
        [deleteInProgress, lockInProgress, unlockInProgress]
    );

    const buttons: TButtons = [];
    buttons.push(changeView);
    buttons.push(sortTiles);
    buttons.push({
        id: '2',
        leftIcon: AddNewIcon,
        children: 'Create New Project',
        color: 'gray',
        variant: 'text',
        onClick: () => {
            if (!actionLocked) {
                sideNavStateChange(SideNavMode.createNewProject);
            }
        },
        visible: hasCollaborationSpacePermission(
            CollaborationSpacePermissions.CollaborationSpaceProjectCreate
        ),
    });
    if (Object.keys(currentSelection).length > 0) {
        buttons.push({ id: '4', elementType: MenuElementType.DIVIDER });
        buttons.push({
            id: '3',
            leftIcon: EditIcon,
            children: 'Edit Project',
            color: 'gray',
            variant: 'text',
            onClick: () => {
                if (!actionLocked) {
                    sideNavStateChange(SideNavMode.editProject);
                }
            },
            visible:
                hideModifyButtons &&
                hasCollaborationSpacePermission(
                    CollaborationSpacePermissions.CollaborationSpaceProjectEdit
                ),
        });
        if (currentSelection.latestRevision) {
            buttons.push({
                id: '5',
                leftIcon: currentSelection.status !== 'LOCKED' ? LockIcon : UnLockIcon,
                children: currentSelection.status === 'LOCKED' ? 'Unlock Project' : 'Lock Project',
                color: 'gray',
                variant: 'text',
                onClick: () => {
                    if (!actionLocked) {
                        currentSelection.status === 'LOCKED'
                            ? openUnlockProjectDialog()
                            : openLockProjectDialog();
                    }
                },
                visible:
                    hideModifyButtons && hasProjectPermission(ProjectPermissions.ProjectLockUnlock),
            });
        }
        buttons.push({
            id: '6',
            leftIcon: DeleteIcon,
            children: 'Delete Project',
            color: 'gray',
            variant: 'text',
            onClick: () => {
                if (!actionLocked) {
                    openDeleteProjectDialog();
                }
            },
            visible:
                hideModifyButtons &&
                hasCollaborationSpacePermission(
                    CollaborationSpacePermissions.CollaborationSpaceProjectDelete
                ),
        });
    }

    return (
        <>
            {deleteProjectDialog}
            {lockProjectDialog}
            {unlockProjectDialog}
            {forceProjectDialog}
            {lockedProjectDialog}
            <VerticalMenu buttons={buttons} />
        </>
    );
}
