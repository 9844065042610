import ErrorPage from 'components/templates/ErrorPage';
import { ReactComponent as ErrorLabel } from 'images/error_subscription.svg';

const StaticErrorSubscription = () => {
    return (
        <ErrorPage showLogout showReturnHome={false} errorLabel={<ErrorLabel />}>
            <h1>There was a problem with your Subscription License.</h1>
            <p>Please contact Support to fix the error.</p>
        </ErrorPage>
    );
};

export default StaticErrorSubscription;
